import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  constructor(private translate: TranslateService) {}

  load() {
    // Idioma default
    this.translate.setDefaultLang('en')

    const userLanguage =
      localStorage.getItem('user.language') ?? navigator.language

    // Idioma actual
    if (userLanguage.startsWith('es')) {
      this.translate.use('es')
    } else {
      this.translate.use('en')
    }
  }

  getLanguage(): LanguageCode {
    switch (this.translate.currentLang) {
      case 'en':
        return LanguageCode.EN
      case 'es':
        return LanguageCode.ES
      default:
        return LanguageCode.EN
    }
  }

  setLanguage(code: LanguageCode) {
    switch (code) {
      case LanguageCode.EN:
        localStorage.setItem('user.language', 'en')
        this.translate.use('en')
        break
      case LanguageCode.ES:
        localStorage.setItem('user.language', 'es')
        this.translate.use('es')
        break
    }
  }

  getService() {
    return this.translate
  }
}

export enum LanguageCode {
  EN,
  ES
}
