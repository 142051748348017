import { CommonModule } from '@angular/common'
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core'
import {
  RouterOutlet,
  RouterLink,
  RouterLinkActive,
  ActivatedRoute
} from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import {
  faAngleDown,
  faBars,
  faBell,
  faBug,
  faChevronDown,
  faChevronUp,
  faCoins,
  faCookieBite,
  faGears,
  faLanguage,
  faRightFromBracket,
  faRobot,
  faServer
} from '@fortawesome/free-solid-svg-icons'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import {
  LanguageCode,
  LocaleService
} from 'src/app/core/services/locale.service'
import {
  LoginService,
  LoginStateType
} from 'src/app/core/services/login.service'
import { SidebarModule } from 'primeng/sidebar'
import { TooltipModule } from 'primeng/tooltip'
import { DialogModule } from 'primeng/dialog'
import { ButtonModule } from 'primeng/button'
import { InputTextModule } from 'primeng/inputtext'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { FormsModule } from '@angular/forms'
import { LoaderService } from 'src/app/core/services/loader.service'
import * as Sentry from '@sentry/angular'
import { SwitchInputComponent } from 'src/app/commons/components/panel/form/inputs/switch-input/switch-input.component'
import { MessageService } from 'primeng/api'
import { AiPlansPageComponent } from '../../gate/payments/info/ai-plans-page/ai-plans-page.component'
import { ZergroshService } from 'src/app/core/services/zergrosh.service'
import { BsDropdownDirective, BsDropdownModule } from 'ngx-bootstrap/dropdown'

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    FontAwesomeModule,
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    TranslateModule,
    SidebarModule,
    TooltipModule,
    DialogModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    FormsModule,
    SwitchInputComponent,
    AiPlansPageComponent,
    BsDropdownModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
  faBars = faBars
  faDiscord = faDiscord
  faRightFromBracket = faRightFromBracket
  faServer = faServer
  faGears = faGears
  faLanguage = faLanguage
  faBug = faBug
  faCoins = faCoins
  faRobot = faRobot
  faAngleDown = faAngleDown
  faBell = faBell
  faCookieBite = faCookieBite
  faChevronDown = faChevronDown
  faChevronUp = faChevronUp

  loginHandledByThisComponent = false
  loginStateSubscription?: Subscription

  doignLogout = false

  param = { value: 'world' }
  userOptionsShow = false

  @Input()
  headerContainerFull: boolean = false

  @Input()
  mobileSidebar: boolean = false

  @Input()
  sidebarTemplate: TemplateRef<unknown> | null = null

  @Input()
  contentMaxWidth = '100vw'

  @ViewChild('usersOptionsRef')
  usersOptionsRef?: ElementRef

  @ViewChild('userDropdown')
  userDropdown!: BsDropdownDirective

  sidebarVisible = false
  reportBugDialogVisible = false
  rerportBugName: string = ''
  reportBugEmail: string = ''
  reportBugMessage: string = ''

  windowWidth = 0

  constructor(
    private loginService: LoginService,
    private localeService: LocaleService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private zergroshService: ZergroshService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.windowWidth = window.innerWidth
    this.loginStateSubscription = this.loginService.loginState.subscribe(
      (state) => {
        if (state === 'NONE') {
          this.userOptionsShow = false
          this.loginHandledByThisComponent = false
        }
      }
    )

    this.activatedRoute.queryParamMap.subscribe({
      next: (paramMap) => {
        const viewPlans = paramMap.get('viewPlans')
        if (
          viewPlans !== null &&
          viewPlans !== undefined &&
          viewPlans === 'true'
        ) {
          this.zergroshService.showAIPricingModal('all', undefined, 'personal')
        }
      }
    })
  }

  ngOnDestroy(): void {
    if (this.loginStateSubscription !== undefined) {
      this.loginStateSubscription.unsubscribe()
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: MouseEvent): void {
    if (
      this.usersOptionsRef !== undefined &&
      !this.usersOptionsRef.nativeElement.contains(event.target)
    ) {
      this.userOptionsShow = false
      this.userDropdown.hide()
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth
  }

  getLoginState(): LoginStateType {
    return this.loginService.loginState.value
  }

  openLoginPopUp() {
    if (this.getLoginState() !== 'LOGGED') {
      this.loginService.openLoginPopUp().subscribe()
      this.loginHandledByThisComponent = true
    }
  }

  getLoggedUserName() {
    return this.loginService.loginData.value.discord?.userInfo?.global_name
  }

  getLoggedUserAvatar() {
    return this.loginService.getLoggedUserAvatarURL()
  }

  doLogout() {
    this.doignLogout = true
    this.loginService.logout(this.activatedRoute, false, () => {
      this.doignLogout = false
    })
  }

  getLanguage() {
    switch (this.localeService.getLanguage()) {
      case LanguageCode.EN:
        return 'EN'
      case LanguageCode.ES:
        return 'ES'
    }
  }

  changeLanguage(code: string) {
    switch (code) {
      case 'es':
        this.localeService.setLanguage(LanguageCode.ES)
        break
      default:
        this.localeService.setLanguage(LanguageCode.EN)
        break
    }
  }

  hideSidebar() {
    this.sidebarVisible = false
  }

  submitBugReport() {
    this.loaderService.setDisplay(false)
    this.reportBugDialogVisible = false

    if (this.reportBugMessage.trim().length === 0) {
      return
    }

    Sentry.sendFeedback(
      {
        name: this.rerportBugName, // optional
        email: this.reportBugEmail, // optional
        message: this.reportBugMessage // required
      },
      {
        includeReplay: false // optional
      }
    )
      .then(() => {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant(
            'header.feedback-dialog.success.title'
          ),
          detail: this.translateService.instant(
            'header.feedback-dialog.success.details'
          ),
          life: 20000
        })
        this.loaderService.setDisplay(false)
        this.reportBugDialogVisible = false
        this.reportBugEmail = ''
        this.reportBugMessage = ''
        this.rerportBugName = ''
      })
      .catch(() => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant(
            'header.feedback-dialog.error.title'
          ),
          detail: this.translateService.instant(
            'header.feedback-dialog.error.details'
          ),
          life: 20000
        })
        this.loaderService.setDisplay(false)
        this.reportBugDialogVisible = false
        this.reportBugEmail = ''
        this.reportBugMessage = ''
        this.rerportBugName = ''
      })
  }

  getShowAIModal() {
    return this.zergroshService.showAIModal.getValue()
  }

  getShowAIModalDisplayMode() {
    return this.zergroshService.showAIModalDisplayMode.getValue()
  }

  getShowAIModalServerId() {
    return this.zergroshService.showAIModalDisplayServerId.getValue()
  }

  getShowAIModalSelectedPrice() {
    return this.zergroshService.showAIModalSelectedPrice.getValue()
  }

  hideAIModal() {
    this.zergroshService.showAIModal.next(false)
  }

  showAIModal() {
    this.zergroshService.showAIPricingModal('all', undefined, 'personal')
  }

  showUserMenu() {
    if (!this.userOptionsShow) {
      this.userOptionsShow = true
      this.userDropdown.show()
    } else {
      this.userOptionsShow = false
      this.userDropdown.hide()
    }
  }

  d() {
    return this.loginService.loginState.getValue()
  }
}
