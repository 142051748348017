import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  injectScript(scriptContent: string): void {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.text = scriptContent
    document.body.appendChild(script)
  }

  injectScriptSRCTo(nativeElement: unknown, scriptsrc: string, id?: string) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = scriptsrc
    script.id = id ?? ''

    if (id !== undefined) {
      const scriptExisting = document.getElementById(id)
      scriptExisting?.remove()
    }

    const nativeElementNode = nativeElement as Node
    nativeElementNode.appendChild(script)
  }
}
