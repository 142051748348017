import { CommonModule } from '@angular/common'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { RouterModule } from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import {
  faCookie,
  faFileContract,
  faLock
} from '@fortawesome/free-solid-svg-icons'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { ScriptService } from 'src/app/core/services/script.service'

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [TranslateModule, CommonModule, RouterModule, FontAwesomeModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {
  faCookie = faCookie
  faFileContract = faFileContract
  faLock = faLock

  dateStringi18n = { year: '' }
  dateYearStart = 2024

  currentLang = 'en'

  @ViewChild('termsCondition')
  termsCondition!: ElementRef

  constructor(
    private scriptService: ScriptService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    const date = new Date()
    if (this.dateYearStart < date.getFullYear()) {
      this.dateStringi18n.year = this.dateYearStart + ' - ' + date.getFullYear()
    } else {
      this.dateStringi18n.year = this.dateYearStart + ''
    }

    this.currentLang = this.translateService.currentLang
    this.translateService.onLangChange.subscribe((newLang) => {
      this.currentLang = newLang.lang
    })
  }
}
