<app-popup-fullscreen (escButtonClick)="escButtonClick.emit()">
  <div class="container" header>
    <div class="text-center">
      <h1 class="rainbow-text">{{ 'zergrosh-ai-plans.title' | translate }}</h1>
      <p class="d-inline-block mb-5">{{ 'zergrosh-ai-plans.subtitle' | translate }}</p>
    </div>
  </div>
  <div class="container mb-5">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="row justify-content-center">
          <div class="col-12 text-center mb-5">
            <p-selectButton 
              [options]="priceTypeOptions"
              [(ngModel)]="selectedPriceType"
              optionLabel="label" 
              optionValue="value"
              [allowEmpty]="false"
            />
          </div>
        </div>
        <div class="row justify-content-center" *ngIf="displayMode === 'server' && displayServerModel !== undefined && selectedPriceType !== 'personal'">
          <div class="col-12">
            <div class="text-center mb-2">
              <p-divider align="center" type="dotted">
                <h4 class="p-0 m-0">{{ 'zergrosh-ai-plans.selected-server.add-credits-to-server' | translate }}</h4>
              </p-divider>
            </div>
          </div>
          <div class="col-12 col-xl-9 mb-3">
            <div class="bg-dark p-4 d-flex rainbow-border">
              <div class="d-flex align-items-center">
                <p-avatar 
                  *ngIf="displayServerModel?.icon === null || displayServerModel?.icon === undefined"
                  label="{{ displayServerModel.name.substring(0,2).toUpperCase()}} " 
                  [ngClass]="['me-2','bg-dark']"
                  [style]="{ color: '#ffffff', width: '64px', 'height': '64px', 'font-size': '32px' }"
                ></p-avatar>
                <img 
                  *ngIf="displayServerModel.icon !== null && displayServerModel.icon !== undefined"
                  src="https://cdn.discordapp.com/icons/{{ displayServerModel.id }}/{{ displayServerModel.icon }}.png" 
                  style="width: 64px" 
                  class="me-2"
                />
              </div>
              <div class="ms-3 d-flex align-items-center">
                <b class="fs-4 text-break">{{displayServerModel.name}}</b>
              </div>
            </div>
          </div>
        </div>
        <div class="d-block mb-5" *ngIf="displayMode !== 'all'"></div>
        <div class="row justify-content-center gx-3">
          <ng-container *ngIf="loading === true">
            <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <div class="bg-dark rounded rounded-4 border border-gold p-4 h-100">
                <p-skeleton height="28px" styleClass="mb-2" />
                <p-skeleton height="24px" width="50%" styleClass="mb-4" />
                <p-skeleton height="32px" width="50%" styleClass="mb-2" />
                <p-skeleton height="24px" width="70%" styleClass="mb-4" />
                <p-skeleton height="35px" styleClass="mb-4" />
                <p-skeleton height="25px" width="50%" styleClass="mb-3" />
                <p-skeleton height="25px" width="70%" styleClass="mb-3" />
                <p-skeleton height="25px" width="40%" styleClass="mb-3" />
                <p-skeleton height="25px" styleClass="mb-3" />
              </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
                <p-skeleton height="28px" styleClass="mb-2" />
                <p-skeleton height="24px" width="50%" styleClass="mb-4" />
                <p-skeleton height="32px" width="50%" styleClass="mb-2" />
                <p-skeleton height="24px" width="70%" styleClass="mb-4" />
                <p-skeleton height="35px" styleClass="mb-4" />
                <p-skeleton height="25px" width="50%" styleClass="mb-3" />
                <p-skeleton height="25px" width="70%" styleClass="mb-3" />
                <p-skeleton height="25px" width="40%" styleClass="mb-3" />
                <p-skeleton height="25px" styleClass="mb-3" />
              </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
                <p-skeleton height="28px" styleClass="mb-2" />
                <p-skeleton height="24px" width="50%" styleClass="mb-4" />
                <p-skeleton height="32px" width="50%" styleClass="mb-2" />
                <p-skeleton height="24px" width="70%" styleClass="mb-4" />
                <p-skeleton height="35px" styleClass="mb-4" />
                <p-skeleton height="25px" width="50%" styleClass="mb-3" />
                <p-skeleton height="25px" width="70%" styleClass="mb-3" />
                <p-skeleton height="25px" width="40%" styleClass="mb-3" />
                <p-skeleton height="25px" styleClass="mb-3" />
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedPriceType === 'personal' && loading === false">
            <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <div class="bg-dark rounded rounded-4 border border-gold p-4 h-100">
                <div class="d-flex justify-content-between">
                  <h4 class="m-0 p-0">{{ 'zergrosh-ai-plans.plans.credits' | translate: { credits: getProductCredits('AIC50P') } }}</h4>
                </div>
                <div class="mt-1">
                  <span class="badge rounded-pill border border-gold text-white bg-gold bg-opacity-25 me-1">{{ 'zergrosh-ai-plans.plans.badges.best-price' | translate }}</span>
                  <span class="badge rounded-pill border border-green text-white bg-green bg-opacity-25">{{ 'zergrosh-ai-plans.plans.badges.personal' | translate }}</span>
                </div>
                <div class="pt-4 text-gold">
                  <h2 class="d-inline-block mb-0 p-0">${{ getProductPrice('AIC50P') }}</h2> {{ getProductCurrency('AIC50P') }}
                </div>
                <div class="text-gray font-14 mt-1">
                  {{ 'zergrosh-ai-plans.plans.cards.only-one-payment' | translate }}
                </div>
                <div class="mt-4">
                  <button class="btn btn-blue text-white fw-bold w-100 rounded rounded-3 btn-lg font-14" (click)="createPayment('AIC50P')" [disabled]="getLoginState() === 'LOGOUT_IN_PROGRESS' ||getLoginState() === 'LOGIN_IN_PROGRESS'">{{ 'zergrosh-ai-plans.plans.cards.buttons.get-credits' | translate }}</button>
                </div>
                <div class="mt-4 font-14">
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.best-price' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.only-pay-what-you-use' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.no-expiration' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.add-more-credits-when-needed' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.use-credits-where-zergrosh-is' | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
                <div class="d-flex justify-content-between">
                  <h4 class="m-0 p-0">{{ 'zergrosh-ai-plans.plans.credits' | translate: { credits: getProductCredits('AIC30P') } }}</h4>
                </div>
                <div class="mt-1">
                  <span class="badge rounded-pill border border-blue text-white bg-blue bg-opacity-25 me-1">{{ 'zergrosh-ai-plans.plans.badges.popular' | translate }}</span>
                  <span class="badge rounded-pill border border-green text-white bg-green bg-opacity-25">{{ 'zergrosh-ai-plans.plans.badges.personal' | translate }}</span>
                </div>
                <div class="pt-4 text-light">
                  <h2 class="d-inline-block mb-0 p-0">${{ getProductPrice('AIC30P') }}</h2> {{ getProductCurrency('AIC30P') }}
                </div>
                <div class="text-gray font-14 mt-1">
                  {{ 'zergrosh-ai-plans.plans.cards.only-one-payment' | translate }}
                </div>
                <div class="mt-4">
                  <button class="btn btn-secondary text-white fw-bold w-100 rounded rounded-3 btn-lg font-14" (click)="createPayment('AIC30P')" [disabled]="getLoginState() === 'LOGOUT_IN_PROGRESS' ||getLoginState() === 'LOGIN_IN_PROGRESS'">{{ 'zergrosh-ai-plans.plans.cards.buttons.get-credits' | translate }}</button>
                </div>
                <div class="mt-4 font-14">
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.only-pay-what-you-use' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.no-expiration' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.add-more-credits-when-needed' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.use-credits-where-zergrosh-is' | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
                <div class="d-flex justify-content-between">
                  <h4 class="m-0 p-0">{{ 'zergrosh-ai-plans.plans.credits' | translate: { credits: getProductCredits('AIC20P') } }}</h4>
                </div>
                <div class="mt-1">
                  <span class="badge rounded-pill border border-green text-white bg-green bg-opacity-25">{{ 'zergrosh-ai-plans.plans.badges.personal' | translate }}</span>
                </div>
                <div class="pt-4 text-light">
                  <h2 class="d-inline-block mb-0 p-0">${{ getProductPrice('AIC20P') }}</h2> {{ getProductCurrency('AIC20P') }}
                </div>
                <div class="text-gray font-14 mt-1">
                  {{ 'zergrosh-ai-plans.plans.cards.only-one-payment' | translate }}
                </div>
                <div class="mt-4">
                  <button class="btn btn-secondary text-white fw-bold w-100 rounded rounded-3 btn-lg font-14" (click)="createPayment('AIC20P')" [disabled]="getLoginState() === 'LOGOUT_IN_PROGRESS' ||getLoginState() === 'LOGIN_IN_PROGRESS'">{{ 'zergrosh-ai-plans.plans.cards.buttons.get-credits' | translate }}</button>
                </div>
                <div class="mt-4 font-14">
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.only-pay-what-you-use' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.no-expiration' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.add-more-credits-when-needed' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.use-credits-where-zergrosh-is' | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="selectedPriceType === 'server' && loading === false">
            <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <div class="bg-dark rounded rounded-4 border border-gold p-4 h-100">
                <div class="d-flex justify-content-between">
                  <h4 class="m-0 p-0">{{ 'zergrosh-ai-plans.plans.credits' | translate: { credits: getProductCredits('AIC50S') } }}</h4>
                </div>
                <div class="mt-1">
                  <span class="badge rounded-pill border border-gold text-white bg-gold bg-opacity-25 me-1">{{ 'zergrosh-ai-plans.plans.badges.best-price' | translate }}</span>
                  <span class="badge rounded-pill border border-primary text-white bg-primary bg-opacity-25">{{ 'zergrosh-ai-plans.plans.badges.server' | translate }}</span>
                </div>
                <div class="pt-4 text-gold">
                  <h2 class="d-inline-block mb-0 p-0">${{ getProductPrice('AIC50S') }}</h2> {{ getProductCurrency('AIC50S') }}
                </div>
                <div class="text-gray font-14 mt-1">
                  {{ 'zergrosh-ai-plans.plans.cards.only-one-payment' | translate }}
                </div>
                <div class="mt-4">
                  <button class="btn btn-blue text-white fw-bold w-100 rounded rounded-3 btn-lg font-14" (click)="createPaymentForServer('AIC50S')" [disabled]="getLoginState() === 'LOGOUT_IN_PROGRESS' ||getLoginState() === 'LOGIN_IN_PROGRESS'">{{ 'zergrosh-ai-plans.plans.cards.buttons.get-credits' | translate }}</button>
                </div>
                <div class="mt-4 font-14">
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.best-price' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.only-pay-what-you-use' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.no-expiration' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.add-more-credits-when-needed' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.use-credits-where-zergrosh-is' | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
                <div class="d-flex justify-content-between">
                  <h4 class="m-0 p-0">{{ 'zergrosh-ai-plans.plans.credits' | translate: { credits: getProductCredits('AIC30S') } }}</h4>
                </div>
                <div class="mt-1">
                  <span class="badge rounded-pill border border-blue text-white bg-blue bg-opacity-25 me-1">{{ 'zergrosh-ai-plans.plans.badges.popular' | translate }}</span>
                  <span class="badge rounded-pill border border-primary text-white bg-primary bg-opacity-25">{{ 'zergrosh-ai-plans.plans.badges.server' | translate }}</span>
                </div>
                <div class="pt-4 text-light">
                  <h2 class="d-inline-block mb-0 p-0">${{ getProductPrice('AIC30S') }}</h2> {{ getProductCurrency('AIC30S') }}
                </div>
                <div class="text-gray font-14 mt-1">
                  {{ 'zergrosh-ai-plans.plans.cards.only-one-payment' | translate }}
                </div>
                <div class="mt-4">
                  <button class="btn btn-secondary text-white fw-bold w-100 rounded rounded-3 btn-lg font-14" (click)="createPaymentForServer('AIC30S')" [disabled]="getLoginState() === 'LOGOUT_IN_PROGRESS' ||getLoginState() === 'LOGIN_IN_PROGRESS'">{{ 'zergrosh-ai-plans.plans.cards.buttons.get-credits' | translate }}</button>
                </div>
                <div class="mt-4 font-14">
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.only-pay-what-you-use' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.no-expiration' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.add-more-credits-when-needed' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.use-credits-where-zergrosh-is' | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
              <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
                <div class="d-flex justify-content-between">
                  <h4 class="m-0 p-0">{{ 'zergrosh-ai-plans.plans.credits' | translate: { credits: getProductCredits('AIC20S') } }}</h4>
                </div>
                <div class="mt-1">
                  <span class="badge rounded-pill border border-primary text-white bg-primary bg-opacity-25">{{ 'zergrosh-ai-plans.plans.badges.server' | translate }}</span>
                </div>
                <div class="pt-4 text-light">
                  <h2 class="d-inline-block mb-0 p-0">${{ getProductPrice('AIC20S') }}</h2> {{ getProductCurrency('AIC20S') }}
                </div>
                <div class="text-gray font-14 mt-1">
                  {{ 'zergrosh-ai-plans.plans.cards.only-one-payment' | translate }}
                </div>
                <div class="mt-4">
                  <button class="btn btn-secondary text-white fw-bold w-100 rounded rounded-3 btn-lg font-14" (click)="createPaymentForServer('AIC20S')" [disabled]="getLoginState() === 'LOGOUT_IN_PROGRESS' ||getLoginState() === 'LOGIN_IN_PROGRESS'">{{ 'zergrosh-ai-plans.plans.cards.buttons.get-credits' | translate }}</button>
                </div>
                <div class="mt-4 font-14">
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.only-pay-what-you-use' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.no-expiration' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.add-more-credits-when-needed' | translate }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-2">
                    <fa-icon [icon]="faCheck" class="me-2 text-green" />
                    <span>
                      {{ 'zergrosh-ai-plans.plans.cards.comparison.use-credits-where-zergrosh-is' | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <p-divider align="center" type="dotted" styleClass="bg-body">
          <h4 class="m-0 p-0">{{ 'zergrosh-ai-plans.more-buy-options-section' | translate }}</h4>
        </p-divider>
      </div>
    </div>
    <div class="row justify-content-center mt-2 mb-5">
      <ng-container *ngIf="loading === true">
        <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
          <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
            <p-skeleton height="28px" styleClass="mb-2" />
            <p-skeleton height="24px" width="50%" styleClass="mb-4" />
            <p-skeleton height="32px" width="50%" styleClass="mb-2" />
            <p-skeleton height="24px" width="70%" styleClass="mb-4" />
            <p-skeleton height="35px" styleClass="mb-4" />
            <p-skeleton height="25px" width="50%" styleClass="mb-3" />
            <p-skeleton height="25px" width="70%" styleClass="mb-3" />
            <p-skeleton height="25px" width="40%" styleClass="mb-3" />
            <p-skeleton height="25px" styleClass="mb-3" />
          </div>
        </div>
        <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
          <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
            <p-skeleton height="28px" styleClass="mb-2" />
            <p-skeleton height="24px" width="50%" styleClass="mb-4" />
            <p-skeleton height="32px" width="50%" styleClass="mb-2" />
            <p-skeleton height="24px" width="70%" styleClass="mb-4" />
            <p-skeleton height="35px" styleClass="mb-4" />
            <p-skeleton height="25px" width="50%" styleClass="mb-3" />
            <p-skeleton height="25px" width="70%" styleClass="mb-3" />
            <p-skeleton height="25px" width="40%" styleClass="mb-3" />
            <p-skeleton height="25px" styleClass="mb-3" />
          </div>
        </div>
        <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
          <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
            <p-skeleton height="28px" styleClass="mb-2" />
            <p-skeleton height="24px" width="50%" styleClass="mb-4" />
            <p-skeleton height="32px" width="50%" styleClass="mb-2" />
            <p-skeleton height="24px" width="70%" styleClass="mb-4" />
            <p-skeleton height="35px" styleClass="mb-4" />
            <p-skeleton height="25px" width="50%" styleClass="mb-3" />
            <p-skeleton height="25px" width="70%" styleClass="mb-3" />
            <p-skeleton height="25px" width="40%" styleClass="mb-3" />
            <p-skeleton height="25px" styleClass="mb-3" />
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedPriceType === 'personal' && loading === false">
        <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
          <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
            <div class="d-flex justify-content-between">
              <h4 class="m-0 p-0">{{ 'zergrosh-ai-plans.plans.credits' | translate: { credits: getProductCredits('AIC15P') } }}</h4>
            </div>
            <div class="mt-1">
              <span class="badge rounded-pill border border-green text-white bg-green bg-opacity-25">{{ 'zergrosh-ai-plans.plans.badges.personal' | translate }}</span>
            </div>
            <div class="pt-4 text-light">
              <h2 class="d-inline-block mb-0 p-0">${{ getProductPrice('AIC15P') }}</h2> {{ getProductCurrency('AIC15P') }}
            </div>
            <div class="text-gray font-14 mt-1">
              {{ 'zergrosh-ai-plans.plans.cards.only-one-payment' | translate }}
            </div>
            <div class="mt-4">
              <button class="btn btn-secondary text-white fw-bold w-100 rounded rounded-3 btn-lg font-14" (click)="createPayment('AIC15P')" [disabled]="getLoginState() === 'LOGOUT_IN_PROGRESS' ||getLoginState() === 'LOGIN_IN_PROGRESS'">{{ 'zergrosh-ai-plans.plans.cards.buttons.get-credits' | translate }}</button>
            </div>
            <div class="mt-4 font-14">
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.only-pay-what-you-use' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.no-expiration' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.add-more-credits-when-needed' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.use-credits-where-zergrosh-is' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
          <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
            <div class="d-flex justify-content-between">
              <h4 class="m-0 p-0">{{ 'zergrosh-ai-plans.plans.credits' | translate: { credits: getProductCredits('AIC10P') } }}</h4>
            </div>
            <div class="mt-1">
              <span class="badge rounded-pill border border-green text-white bg-green bg-opacity-25">{{ 'zergrosh-ai-plans.plans.badges.personal' | translate }}</span>
            </div>
            <div class="pt-4 text-light">
              <h2 class="d-inline-block mb-0 p-0">${{ getProductPrice('AIC10P') }}</h2> {{ getProductCurrency('AIC10P') }}
            </div>
            <div class="text-gray font-14 mt-1">
              {{ 'zergrosh-ai-plans.plans.cards.only-one-payment' | translate }}
            </div>
            <div class="mt-4">
              <button class="btn btn-secondary text-white fw-bold w-100 rounded rounded-3 btn-lg font-14" (click)="createPayment('AIC10P')" [disabled]="getLoginState() === 'LOGOUT_IN_PROGRESS' ||getLoginState() === 'LOGIN_IN_PROGRESS'">{{ 'zergrosh-ai-plans.plans.cards.buttons.get-credits' | translate }}</button>
            </div>
            <div class="mt-4 font-14">
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.only-pay-what-you-use' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.no-expiration' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.add-more-credits-when-needed' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.use-credits-where-zergrosh-is' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
          <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
            <div class="d-flex justify-content-between">
              <h4 class="m-0 p-0">{{ 'zergrosh-ai-plans.plans.credits' | translate: { credits: getProductCredits('AIC05P') } }}</h4>
            </div>
            <div class="mt-1">
              <span class="badge rounded-pill border border-green text-white bg-green bg-opacity-25">{{ 'zergrosh-ai-plans.plans.badges.personal' | translate }}</span>
            </div>
            <div class="pt-4 text-light">
              <h2 class="d-inline-block mb-0 p-0">${{ getProductPrice('AIC05P') }}</h2> {{ getProductCurrency('AIC05P') }}
            </div>
            <div class="text-gray font-14 mt-1">
              {{ 'zergrosh-ai-plans.plans.cards.only-one-payment' | translate }}
            </div>
            <div class="mt-4">
              <button class="btn btn-secondary text-white fw-bold w-100 rounded rounded-3 btn-lg font-14" (click)="createPayment('AIC05P')" [disabled]="getLoginState() === 'LOGOUT_IN_PROGRESS' ||getLoginState() === 'LOGIN_IN_PROGRESS'">{{ 'zergrosh-ai-plans.plans.cards.buttons.get-credits' | translate }}</button>
            </div>
            <div class="mt-4 font-14">
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.only-pay-what-you-use' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.no-expiration' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.add-more-credits-when-needed' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.use-credits-where-zergrosh-is' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedPriceType === 'server' && loading === false">
        <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
          <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
            <div class="d-flex justify-content-between">
              <h4 class="m-0 p-0">{{ 'zergrosh-ai-plans.plans.credits' | translate: { credits: getProductCredits('AIC15S') } }}</h4>
            </div>
            <div class="mt-1">
              <span class="badge rounded-pill border border-primary text-white bg-primary bg-opacity-25">{{ 'zergrosh-ai-plans.plans.badges.server' | translate }}</span>
            </div>
            <div class="pt-4 text-light">
              <h2 class="d-inline-block mb-0 p-0">${{ getProductPrice('AIC15S') }}</h2> {{ getProductCurrency('AIC15S') }}
            </div>
            <div class="text-gray font-14 mt-1">
              {{ 'zergrosh-ai-plans.plans.cards.only-one-payment' | translate }}
            </div>
            <div class="mt-4">
              <button class="btn btn-secondary text-white fw-bold w-100 rounded rounded-3 btn-lg font-14" (click)="createPaymentForServer('AIC15S')" [disabled]="getLoginState() === 'LOGOUT_IN_PROGRESS' ||getLoginState() === 'LOGIN_IN_PROGRESS'">{{ 'zergrosh-ai-plans.plans.cards.buttons.get-credits' | translate }}</button>
            </div>
            <div class="mt-4 font-14">
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.only-pay-what-you-use' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.no-expiration' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.add-more-credits-when-needed' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.use-credits-where-zergrosh-is' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
          <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
            <div class="d-flex justify-content-between">
              <h4 class="m-0 p-0">{{ 'zergrosh-ai-plans.plans.credits' | translate: { credits: getProductCredits('AIC10S') } }}</h4>
            </div>
            <div class="mt-1">
              <span class="badge rounded-pill border border-primary text-white bg-primary bg-opacity-25">{{ 'zergrosh-ai-plans.plans.badges.server' | translate }}</span>
            </div>
            <div class="pt-4 text-light">
              <h2 class="d-inline-block mb-0 p-0">${{ getProductPrice('AIC10S') }}</h2> {{ getProductCurrency('AIC10S') }}
            </div>
            <div class="text-gray font-14 mt-1">
              {{ 'zergrosh-ai-plans.plans.cards.only-one-payment' | translate }}
            </div>
            <div class="mt-4">
              <button class="btn btn-secondary text-white fw-bold w-100 rounded rounded-3 btn-lg font-14" (click)="createPaymentForServer('AIC10S')" [disabled]="getLoginState() === 'LOGOUT_IN_PROGRESS' ||getLoginState() === 'LOGIN_IN_PROGRESS'">{{ 'zergrosh-ai-plans.plans.cards.buttons.get-credits' | translate }}</button>
            </div>
            <div class="mt-4 font-14">
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.only-pay-what-you-use' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.no-expiration' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.add-more-credits-when-needed' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.use-credits-where-zergrosh-is' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
          <div class="bg-dark rounded rounded-4 border border-blueblack p-4 h-100">
            <div class="d-flex justify-content-between">
              <h4 class="m-0 p-0">{{ 'zergrosh-ai-plans.plans.credits' | translate: { credits: getProductCredits('AIC05S') } }}</h4>
            </div>
            <div class="mt-1">
              <span class="badge rounded-pill border border-primary text-white bg-primary bg-opacity-25">{{ 'zergrosh-ai-plans.plans.badges.server' | translate }}</span>
            </div>
            <div class="pt-4 text-light">
              <h2 class="d-inline-block mb-0 p-0">${{ getProductPrice('AIC05S') }}</h2> {{ getProductCurrency('AIC05S') }}
            </div>
            <div class="text-gray font-14 mt-1">
              {{ 'zergrosh-ai-plans.plans.cards.only-one-payment' | translate }}
            </div>
            <div class="mt-4">
              <button class="btn btn-secondary text-white fw-bold w-100 rounded rounded-3 btn-lg font-14" (click)="createPaymentForServer('AIC05S')" [disabled]="getLoginState() === 'LOGOUT_IN_PROGRESS' ||getLoginState() === 'LOGIN_IN_PROGRESS'">{{ 'zergrosh-ai-plans.plans.cards.buttons.get-credits' | translate }}</button>
            </div>
            <div class="mt-4 font-14">
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.only-pay-what-you-use' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.no-expiration' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.add-more-credits-when-needed' | translate }}
                </span>
              </div>
              <div class="d-flex align-items-center mb-2">
                <fa-icon [icon]="faCheck" class="me-2 text-green" />
                <span>
                  {{ 'zergrosh-ai-plans.plans.cards.comparison.use-credits-where-zergrosh-is' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div id="faq" style="transform: translateY(-70px);"></div>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <p-divider align="center" type="dotted" styleClass="bg-body">
          <h4 class="m-0 p-0">{{ 'zergrosh-ai-plans.faq-section' | translate }}</h4>
        </p-divider>
      </div>
    </div>
    <div class="row mt-2 mb-5">
      <div class="col-12 mb-4">
        <p-panel [toggleable]="true" [collapsed]="true" [style.cursor]="'pointer'" (click)="panel1.toggle($event)" #panel1>
          <ng-template pTemplate="header">
            <h5 class="p-0 m-0"><fa-icon [icon]="faQuestion" class="me-3" /> {{ 'zergrosh-ai-plans.plans.faq.what-is-zergrosh-ai.title' | translate }}</h5>
          </ng-template>
          <p class="mb-0 p-0 text-gray pb-3 pt-3" [innerHTML]="'zergrosh-ai-plans.plans.faq.what-is-zergrosh-ai.description.d1' | translate"></p>
        </p-panel>
      </div>
      <div class="col-12 mb-4">
        <p-panel [toggleable]="true" [collapsed]="true" [style.cursor]="'pointer'" (click)="panel2.toggle($event)" #panel2>
          <ng-template pTemplate="header">
            <h5 class="p-0 m-0"><fa-icon [icon]="faQuestion" class="me-3" /> {{ 'zergrosh-ai-plans.plans.faq.where-i-can-use-credits.title' | translate }}</h5>
          </ng-template>
          <p class="text-gray pt-3" [innerHTML]="'zergrosh-ai-plans.plans.faq.where-i-can-use-credits.description.d1' | translate"></p>
          <p class="mb-0 p-0 text-gray pb-3" [innerHTML]="'zergrosh-ai-plans.plans.faq.where-i-can-use-credits.description.d2' | translate"></p>
        </p-panel>
      </div>
      <div class="col-12 mb-4">
        <p-panel [toggleable]="true" [collapsed]="true" [style.cursor]="'pointer'" (click)="panel3.toggle($event)" #panel3>
          <ng-template pTemplate="header">
            <h5 class="p-0 m-0"><fa-icon [icon]="faQuestion" class="me-3" /> {{ 'zergrosh-ai-plans.plans.faq.how-personal-credits-work.title' | translate }}</h5>
          </ng-template>
          <p class="mb-0 p-0 text-gray pb-3 pt-3" [innerHTML]="'zergrosh-ai-plans.plans.faq.how-personal-credits-work.description.d1' | translate"></p>
        </p-panel>
      </div>
      <div class="col-12 mb-4">
        <p-panel [toggleable]="true" [collapsed]="true" [style.cursor]="'pointer'" (click)="panel4.toggle($event)" #panel4>
          <ng-template pTemplate="header">
            <h5 class="p-0 m-0"><fa-icon [icon]="faQuestion" class="me-3" /> {{ 'zergrosh-ai-plans.plans.faq.how-server-credits-work.title' | translate }}</h5>
          </ng-template>
          <p class="text-gray pt-3" [innerHTML]="'zergrosh-ai-plans.plans.faq.how-server-credits-work.description.d1' | translate"></p>
          <p class="mb-0 p-0 text-gray pb-3" [innerHTML]="'zergrosh-ai-plans.plans.faq.how-server-credits-work.description.d2' | translate"></p>
        </p-panel>
      </div>
      <div class="col-12 mb-4">
        <p-panel [toggleable]="true" [collapsed]="true" [style.cursor]="'pointer'" (click)="panel5.toggle($event)" #panel5>
          <ng-template pTemplate="header">
            <h5 class="p-0 m-0"><fa-icon [icon]="faQuestion" class="me-3" /> {{ 'zergrosh-ai-plans.plans.faq.credits-expires.title' | translate }}</h5>
          </ng-template>
          <p class="mb-0 p-0 text-gray pb-3 pt-3" [innerHTML]="'zergrosh-ai-plans.plans.faq.credits-expires.description.d1' | translate"></p>
        </p-panel>
      </div>
      <div class="col-12 mb-4">
        <p-panel [toggleable]="true" [collapsed]="true" [style.cursor]="'pointer'" (click)="panel6.toggle($event)" #panel6>
          <ng-template pTemplate="header">
            <h5 class="p-0 m-0"><fa-icon [icon]="faQuestion" class="me-3" /> {{ 'zergrosh-ai-plans.plans.faq.how-consumption-is-calculated.title' | translate }}</h5>
          </ng-template>
          <p class="text-gray pt-3" [innerHTML]="'zergrosh-ai-plans.plans.faq.how-consumption-is-calculated.description.d1' | translate"></p>
          <p class="text-gray" [innerHTML]="'zergrosh-ai-plans.plans.faq.how-consumption-is-calculated.description.d2' | translate"></p>
          <p class="mb-0 p-0 text-gray pb-3" [innerHTML]="'zergrosh-ai-plans.plans.faq.how-consumption-is-calculated.description.d3' | translate"></p>
        </p-panel>
      </div>
      <div class="col-12 mb-4">
        <p-panel [toggleable]="true" [collapsed]="true" [style.cursor]="'pointer'" (click)="panel7.toggle($event)" #panel7>
          <ng-template pTemplate="header">
            <h5 class="p-0 m-0"><fa-icon [icon]="faQuestion" class="me-3" /> {{ 'zergrosh-ai-plans.plans.faq.how-view-consumption.title' | translate }}</h5>
          </ng-template>
          <p class="mb-0 p-0 text-gray pb-3 pt-3" [innerHTML]="'zergrosh-ai-plans.plans.faq.how-view-consumption.description.d1' | translate"></p>
        </p-panel>
      </div>
      <div class="col-12 mb-4">
        <p-panel [toggleable]="true" [collapsed]="true" [style.cursor]="'pointer'" (click)="panel8.toggle($event)" #panel8>
          <ng-template pTemplate="header">
            <h5 class="p-0 m-0"><fa-icon [icon]="faQuestion" class="me-3" /> {{ 'zergrosh-ai-plans.plans.faq.how-view-remaining-credits.title' | translate }}</h5>
          </ng-template>
          <p class="mb-0 p-0 text-gray pb-3 pt-3" [innerHTML]="'zergrosh-ai-plans.plans.faq.how-view-remaining-credits.description.d1' | translate"></p>
        </p-panel>
      </div>
      <div class="col-12 mb-4">
        <p-panel [toggleable]="true" [collapsed]="true" [style.cursor]="'pointer'" (click)="panel9.toggle($event)" #panel9>
          <ng-template pTemplate="header">
            <h5 class="p-0 m-0"><fa-icon [icon]="faQuestion" class="me-3" /> {{ 'zergrosh-ai-plans.plans.faq.request-cancellation.title' | translate }}</h5>
          </ng-template>
          <p class="mb-0 p-0 text-gray pb-3 pt-3" [innerHTML]="'zergrosh-ai-plans.plans.faq.request-cancellation.description.d1' | translate"></p>
        </p-panel>
      </div>
      <div class="col-12 mb-4">
        <p-panel [toggleable]="true" [collapsed]="true" [style.cursor]="'pointer'" (click)="panel10.toggle($event)" #panel10>
          <ng-template pTemplate="header">
            <h5 class="p-0 m-0"><fa-icon [icon]="faQuestion" class="me-3" /> {{ 'zergrosh-ai-plans.plans.faq.what-model-we-use.title' | translate }}</h5>
          </ng-template>
          <p class="text-gray pt-3" [innerHTML]="'zergrosh-ai-plans.plans.faq.what-model-we-use.description.d1' | translate"></p>
          <p class="mb-0 p-0 text-gray pb-3" [innerHTML]="'zergrosh-ai-plans.plans.faq.what-model-we-use.description.d2' | translate"></p>
        </p-panel>
      </div>
    </div>
  </div>
</app-popup-fullscreen>

<p-dialog 
  header="Obtener créditos para un servidor" 
  [modal]="true" 
  [(visible)]="showServerSelectorDialog" 
  [style]="{ width: '90vw', 'max-width': '500px' }"
  resizable="false"
  draggable="false"
  focusOnShow="false"
  showHeader="false"
  [contentStyle]="{'margin': '0', 'padding': '0'}"
  dismissableMask="true"
  [baseZIndex]="10000"
  #primeDialog
>
  <ng-template pTemplate="content">
    <div 
      class="bg-blueblack p-4 border-bottom border-black" 
    >
      <h4 class="p-0 m-0">{{ getProductCredits(serverProductToBuy) }} Créditos</h4>
      <div class="mt-1">
        <span class="badge rounded-pill border border-gold text-white bg-gold bg-opacity-25 me-1" *ngIf="serverProductToBuy === 'AIC50S'">{{ 'zergrosh-ai-plans.plans.badges.best-price' | translate }}</span>
        <span class="badge rounded-pill border border-blue text-white bg-blue bg-opacity-25 me-1" *ngIf="serverProductToBuy === 'AIC30S'">{{ 'zergrosh-ai-plans.plans.badges.popular' | translate }}</span>
        <span class="badge rounded-pill border border-primary text-white bg-primary bg-opacity-25">{{ 'zergrosh-ai-plans.plans.badges.server' | translate }}</span>
      </div>
      <div class="pt-4 text-light">
        <h2 class="d-inline-block mb-0 p-0" [class.text-gold]="serverProductToBuy === 'AIC50S'">${{ getProductPrice(serverProductToBuy) }}</h2> {{ getProductCurrency(serverProductToBuy) }}
      </div>
      <div class="text-gray font-14 mt-1">
        {{ 'zergrosh-ai-plans.plans.cards.only-one-payment' | translate }}
      </div>
    </div>
    <div class="p-4 pb-2">
      <div class="text-gray font-14">
        Selecciona el servidor donde quieres añadir los créditos a comprar.
      </div>
      <div class="p-fluid mt-3">
        <div class="alert alert-warning font-14" role="alert" *ngIf="servers.length === 0">
          ¡No hay servidores! Asegúrate de ser el dueño de un servidor o de tener permisos de administrador.
        </div>
        <app-server-dropdown-input
          [onlyShowGuildsWithZergroshAccess]="true"
          [filterBy]="'ONLY_ADMIN_AND_OWNER'"
          [(value)]="selectedServer"
        ></app-server-dropdown-input>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div>
      <button class="btn btn-sm btn-secondary me-2" (click)="showServerSelectorDialog = false">Cancelar</button>
      <button 
        *ngIf="servers.length !== 0" 
        [disabled]="selectedServer === undefined" 
        class="btn btn-sm text-white" 
        [class.btn-primary]="serverProductToBuy !== 'AIC50S'" 
        [class.btn-blue]="serverProductToBuy === 'AIC50S'" 
        (click)="submitPaymentForServer()"
      >
        Continuar
      </button>
    </div>
  </ng-template>
</p-dialog>
