<div class="d-block" style="height: 60px;"></div>
<header class="container-fluid fixed-top bg-dark mt-0 shadow">
  <div [class]="headerContainerFull ? 'container-fluid' : 'container'" *ngIf="!mobileSidebar || windowWidth >= 1200">
    <div class="d-flex justify-content-between">
      <a class="d-flex align-items-center app-name" routerLink="/" [style.--content-max-width]="contentMaxWidth">
        <img src="/assets/img/zergrosh.jpg" width="35px" height="35px" class="me-3" /> 
        <b class="d-none d-md-inline-block">ZERGROSH</b>
      </a>
      <div class="d-flex justify-content-end align-items-center user-container">
        <ng-template [ngTemplateOutlet]="userTemplate"></ng-template>
      </div>
    </div>
  </div>
  <div class="container-fluid" *ngIf="mobileSidebar && windowWidth < 1200" >
    <div class="d-flex flex-row">
      <div class="d-flex align-items-center justify-content-center">
        <span class="d-block p-2 ps-3 pe-3 sidebard-button" (click)="sidebarVisible = !sidebarVisible">
          <fa-icon [icon]="faBars" size="xl" />
        </span>
      </div>
      <div class="flex-grow-1 text-center d-flex align-items-center justify-content-center">
        <a class="d-flex align-items-center app-name app-name-container" routerLink="/">
          <b>ZERGROSH</b>
        </a>
      </div>
      <div class="d-flex justify-content-end align-items-center user-container">
        <ng-template [ngTemplateOutlet]="userTemplate"></ng-template>
      </div>
    </div>
  </div>
</header>
<ng-template #userTemplate>
  <div class="dropdown me-2 ">
    <span class="d-flex h-100 align-items-center me-2 ms-2 fake-button-wrapper" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <span class="fi fi-us me-1" *ngIf="getLanguage() === 'EN'"></span>
      <span class="fi fi-mx me-1" *ngIf="getLanguage() === 'ES'"></span>
      <span class="d-none d-md-inline fake-button">{{getLanguage()}} <fa-icon [icon]="faAngleDown" class="ms-1" size="sm"></fa-icon></span>
    </span>
    <ul class="dropdown-menu dropdown-menu-end mt-1">
      <li>
        <a class="dropdown-item" [class.active]="getLanguage() === 'EN'" [routerLink]="" style="cursor: pointer;" (click)="changeLanguage('en')">
          <span class="d-inline-block" style="width: 30px;"><span class="fi fi-us"></span></span> English
        </a>
      </li>
      <li>
        <a class="dropdown-item" [class.active]="getLanguage() === 'ES'" [routerLink]="" style="cursor: pointer;" (click)="changeLanguage('es')">
          <span class="d-inline-block" style="width: 30px;"><span class="fi fi-mx"></span></span> Español
        </a>
      </li>
    </ul>
  </div>
  <button *ngIf="getLoginState() !== 'LOGGED'" class="user-box btn btn-sm btn-primary" (click)="openLoginPopUp()" [disabled]="getLoginState() === 'LOGIN_IN_PROGRESS'">
    <span *ngIf="!loginHandledByThisComponent && getLoginState() !== 'LOGGED' || getLoginState() === 'NONE' || getLoginState() === 'LOGOUT_IN_PROGRESS'">
      <fa-icon [icon]="faDiscord" class="me-2"></fa-icon> <span class="d-inline-block me-2 ms-2">{{ 'header.login' | translate:param }}</span>
    </span>
    <span *ngIf="loginHandledByThisComponent && getLoginState() === 'LOGIN_OPEN'">
      <img src="/assets/img/loading-round.svg" width="20px" class="me-2" /> <span class="d-inline-block me-2 ms-2">{{ 'header.login' | translate }}</span>
    </span>
    <span *ngIf="loginHandledByThisComponent && getLoginState() === 'LOGIN_IN_PROGRESS'">
      <img src="/assets/img/loading-round.svg" width="20px" class="me-2" /> <span class="d-inline-block me-2  ms-2">{{ 'header.login-in-progress' | translate }}</span>
    </span>
  </button>
  <div dropdown #userDropdown="bs-dropdown" #usersOptionsRef *ngIf="getLoginState() === 'LOGGED'" (click)="showUserMenu()" class="btn-group">
    <div class="d-flex align-items-center" style="cursor:pointer" aria-controls="dropdown-user">
      <button class="d-flex btn btn-primary align-items-center m-0 p-0" type="button" [disabled]="doignLogout" style="border-radius: 50%;">
        <span class="d-flex align-items-center justify-content-center" >
          <img [src]="getLoggedUserAvatar()" width="32px" height="32px" style="border-radius: 50%;" />
        </span>
      </button>
      <div class="not-close-outside">
        <fa-icon [icon]="userOptionsShow ? faChevronUp : faChevronDown" class="ps-2 pe-2"></fa-icon>
      </div>
    </div>
    <ul *dropdownMenu id="dropdown-user" class="dropdown-menu dropdown-menu-end bg-secondary mt-1">
      <li>
        <a class="dropdown-item" routerLink="/me/servers">
          <span class="d-inline-block" style="width: 30px;"><fa-icon [icon]="faServer"></fa-icon></span> {{ 'header.user-dropdown.my-servers' | translate }}
        </a>
      </li>
      <li>
        <a class="dropdown-item" routerLink="/me/billing">
          <span class="d-inline-block" style="width: 30px;"><fa-icon [icon]="faCoins"></fa-icon></span> {{ 'header.user-dropdown.billing' | translate }}
        </a>
      </li>
      <li>
        <a class="dropdown-item" style="cursor: pointer" (click)="showAIModal()">
          <span class="d-inline-block" style="width: 30px;"><fa-icon [icon]="faRobot"></fa-icon></span> {{ 'header.user-dropdown.zergrosh-ai' | translate }}
        </a>
      </li>
      <li>
        <a class="dropdown-item" style="cursor: pointer" (click)="reportBugDialogVisible = true">
          <span class="d-inline-block" style="width: 30px;"><fa-icon [icon]="faBug"></fa-icon></span> {{ 'header.user-dropdown.report-bug' | translate }}
        </a>
      </li>
      <li><hr class="dropdown-divider"></li>
      <li>
        <a class="dropdown-item text-warning" (click)="doLogout();" [routerLink]="" style="cursor: pointer;">
          <span class="d-inline-block" style="width: 30px;"><fa-icon [icon]="faRightFromBracket"></fa-icon></span> {{ 'header.user-dropdown.logout' | translate }}
        </a>
      </li>
    </ul>
  </div>
</ng-template>

<p-sidebar 
  [(visible)]="sidebarVisible" 
  *ngIf="mobileSidebar"
  styleClass="bg-dark panel-sidebar"
  [fullScreen]="true"
>
  <ng-template pTemplate="header">
    <div class="ps-2 logo-box">
      <img src="/assets/img/zergrosh.jpg" width="35px" height="35px" class="me-3" />
      <div class="text">ZERGROSH</div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container *ngTemplateOutlet="sidebarTemplate" />
  </ng-template>
</p-sidebar>

<p-dialog 
  [header]="'header.feedback-dialog.title' | translate" 
  [modal]="true" 
  [(visible)]="reportBugDialogVisible" 
  [breakpoints]="{ '100000px': '50vw', '1199px': '70vw', '768px': '85vw', '575px': '95vw' }"
  maximizable="true"
>
  <span class="text-light d-block mb-3">{{ 'header.feedback-dialog.description' | translate }}</span>
  <div class="d-flex flex-column align-items-start mb-3">
      <label class="fw-semibold mb-2 fs-6 text-gray"><small>{{ 'header.feedback-dialog.email' | translate }}</small></label>
      <div class="p-fluid w-100">
        <input pInputText autocomplete="off" [(ngModel)]="reportBugEmail" />
      </div>
  </div>
  <div class="d-flex flex-column align-items-start mb-3">
      <label class="fw-semibold mb-2 fs-6 text-gray"><small>{{ 'header.feedback-dialog.feedback' | translate }}</small></label>
      <div class="p-fluid w-100">
        <textarea 
          rows="3"
          pInputTextarea 
          [(ngModel)]="reportBugMessage"
        ></textarea>
      </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-end">
        <button class="btn btn-secondary me-1" (click)="reportBugDialogVisible = false">{{ 'header.feedback-dialog.cancel' | translate }}</button>
        <button class="btn btn-primary" (click)="submitBugReport()">{{ 'header.feedback-dialog.submit' | translate }}</button>
    </div>
  </ng-template>
</p-dialog>

<app-ai-plans-page 
  *ngIf="getShowAIModal()" 
  (escButtonClick)="hideAIModal()" 
  [displayMode]="getShowAIModalDisplayMode()" 
  [displayServerId]="getShowAIModalServerId()"
  [selectedPriceType]="getShowAIModalSelectedPrice()"
></app-ai-plans-page>
