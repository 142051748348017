import { CommonModule } from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterLink } from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { TranslateModule } from '@ngx-translate/core'
import { AvatarModule } from 'primeng/avatar'
import { DividerModule } from 'primeng/divider'
import { DropdownModule } from 'primeng/dropdown'
import { map, Observable, Subscription } from 'rxjs'
import { DiscordGuildModel } from 'src/app/core/models/discord-model'
import { DiscordService } from 'src/app/core/services/discord.service'
import { LoginService } from 'src/app/core/services/login.service'
import { ZergroshService } from 'src/app/core/services/zergrosh.service'

@Component({
  selector: 'app-server-dropdown-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AvatarModule,
    DropdownModule,
    TranslateModule,
    FontAwesomeModule,
    DividerModule,
    RouterLink
  ],
  templateUrl: './server-dropdown-input.component.html',
  styleUrl: './server-dropdown-input.component.scss'
})
export class ServerDropdownInputComponent implements OnInit, OnDestroy {
  faPlus = faPlus

  servers: DiscordGuildModel[] = []
  loginStateSubcription?: Subscription

  @Input()
  value?: DiscordGuildModel

  @Input()
  filterBy:
    | 'ALL'
    | 'ONLY_ADMIN'
    | 'ONLY_OWNER'
    | 'ONLY_ADMIN_AND_OWNER'
    | 'NOT_MANAGEABLE' = 'ALL'

  @Input()
  sortByName = true

  @Output()
  valueChange = new EventEmitter<DiscordGuildModel | undefined>()

  @Input()
  enableFilterDropdown = true

  @Input()
  onlyShowGuildsWithZergroshAccess = false

  disabledDueFetchRequired = true
  disabledDueError = false

  constructor(
    private discordService: DiscordService,
    private loginService: LoginService,
    private zergroshService: ZergroshService
  ) {}

  ngOnInit(): void {
    if (this.loginService.loginState.value === 'LOGGED') {
      this.fetchServers()
    } else {
      this.loginStateSubcription = this.loginService.loginState.subscribe({
        next: (newLoginState) => {
          if (newLoginState === 'LOGGED') {
            this.fetchServers()
          }
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.loginStateSubcription?.unsubscribe()
  }

  fetchServers() {
    this.servers = []
    this.disabledDueFetchRequired = true

    let observableToUse: Observable<DiscordGuildModel[]>

    if (this.onlyShowGuildsWithZergroshAccess) {
      observableToUse = this.zergroshService.fetchServersWithZergroshAccess()
    } else {
      observableToUse = this.discordService
        .fetchServers()
        .pipe(map((res) => (res.success ? res.data : [])))
    }

    observableToUse.subscribe({
      next: (servers) => {
        this.disabledDueFetchRequired = false

        switch (this.filterBy) {
          case 'ALL':
            this.servers = servers
            break
          case 'NOT_MANAGEABLE':
            this.servers = servers.filter((g) => {
              return g.owner === false && (parseInt(g.permissions) & 0x8) === 0
            })
            break
          case 'ONLY_ADMIN_AND_OWNER':
            this.servers = servers.filter((g) => {
              return g.owner === true || (parseInt(g.permissions) & 0x8) !== 0
            })
            break
          case 'ONLY_ADMIN':
            this.servers = servers.filter((g) => {
              return g.owner === false && (parseInt(g.permissions) & 0x8) !== 0
            })
            break
          case 'ONLY_OWNER':
            this.servers = servers.filter((g) => {
              return g.owner === true
            })
            break
        }

        if (this.sortByName === true) {
          this.servers = this.servers.sort((a, b) =>
            a.name.localeCompare(b.name)
          )
        }
      },
      error: () => {
        this.disabledDueFetchRequired = false
        this.disabledDueError = true
        this.servers = []
      }
    })
  }
}
