<div class="d-flex flex-column h-100">
  <div #headerElement class="d-print-none">
    <app-header 
      [headerContainerFull]="headerContainerFull" 
      [mobileSidebar]="mobileSidebar"
      [sidebarTemplate]="sidebarTemplate"
      [contentMaxWidth]="contentMaxWidth"
      #appHeader
    ></app-header>
  </div>
  <div [class]="'flex-grow-1' + (bodyOverflowHidden ? ' overflow-hidden' : '')">
    <ng-content *ngIf="showLogged"></ng-content>
    <div class="d-flex h-100 w-100 align-items-center justify-content-center" *ngIf="!showLogged">
      <div class="d-inline-block no-logged-box d-flex flex-column flex-md-row align-items-center justify-content-center bg-dark p-5 rounded mt-5 mb-5">
        <!-- https://www.flaticon.com/free-sticker/padlock_9821260?term=lock&page=1&position=2&origin=search&related_id=9821260 -->
        <img class="sticker" src="/assets/img/stickers/padlock.png" />
        <div class="ms-md-4 text-center text-md-start mt-4 mt-md-0">
          <h3>{{ 'full-layout.login-required.title' | translate }}</h3>
          <p [innerHTML]="'full-layout.login-required.description' | translate | emoji"></p>
          <div>
            <button class="btn btn-primary" (click)="openLoginPopup()" [disabled]="getLoginState() === 'LOGIN_IN_PROGRESS'">
              <fa-icon [icon]="faDiscord" class="me-2" *ngIf="!loginHandledByThisComponent || getLoginState() === 'NONE'"></fa-icon> 
              <img src="/assets/img/loading-round.svg" width="20px" height="20px" class="me-2" *ngIf="loginHandledByThisComponent && getLoginState() !== 'NONE'" />
              {{ 'full-layout.login-required.buttons.login' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showFooter" class="d-print-none">
    <app-footer></app-footer>
  </div>
</div>
