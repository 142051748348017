import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core'
import { HeaderComponent } from '../header/header.component'
import { FooterComponent } from '../footer/footer.component'
import { CommonModule } from '@angular/common'
import {
  LoginService,
  LoginStateType
} from 'src/app/core/services/login.service'
import { Subscription } from 'rxjs'
import { EmojiPipe } from 'src/app/core/pipes/emoji.pipe'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'app-full-layout',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    CommonModule,
    EmojiPipe,
    FontAwesomeModule,
    TranslateModule
  ],
  templateUrl: './full-layout.component.html',
  styleUrl: './full-layout.component.scss'
})
export class FullLayoutComponent implements AfterViewInit, OnDestroy, OnInit {
  faDiscord = faDiscord

  @ViewChild('headerElement')
  headerElement!: ElementRef<HTMLElement>

  @Output()
  headerWidth: EventEmitter<number> = new EventEmitter()

  @Output()
  headerHeight: EventEmitter<number> = new EventEmitter()

  @Input()
  headerContainerFull: boolean = false

  @Input()
  showFooter: boolean = true

  @Input()
  bodyOverflowHidden = false

  @Input()
  mobileSidebar: boolean = false

  @Input()
  sidebarTemplate: TemplateRef<unknown> | null = null

  @Input()
  contentMaxWidth = '100vw'

  @Input()
  requireLogin = false

  @ViewChild('appHeader')
  appHeader!: HeaderComponent

  loginSub?: Subscription
  showLogged!: boolean
  loginHandledByThisComponent = false

  constructor(private loginService: LoginService) {}

  ngOnInit(): void {
    this.loginSub = this.loginService.loginState.subscribe({
      next: (newState) => {
        if (newState === 'NONE' || newState === 'LOGGED') {
          this.loginHandledByThisComponent = false
        }
        if (newState === 'LOGGED') {
          this.showLogged = true
        } else {
          if (this.requireLogin) {
            this.showLogged = false
          } else {
            this.showLogged = true
          }
        }
      }
    })
  }

  ngAfterViewInit(): void {
    this.headerWidth.emit(this.headerElement.nativeElement.offsetWidth)
    this.headerHeight.emit(this.headerElement.nativeElement.offsetHeight)
  }

  ngOnDestroy(): void {
    this.loginSub?.unsubscribe()
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.headerWidth.emit(this.headerElement.nativeElement.offsetWidth)
    this.headerHeight.emit(this.headerElement.nativeElement.offsetHeight)
  }

  hideSidebar() {
    this.appHeader.hideSidebar()
  }

  getLoginState(): LoginStateType {
    return this.loginService.loginState.value
  }

  openLoginPopup() {
    this.loginService.openLoginPopUp().subscribe({
      next: () => {
        this.loginHandledByThisComponent = false
      },
      error: () => {
        this.loginHandledByThisComponent = false
      }
    })
    this.loginHandledByThisComponent = true
  }
}
