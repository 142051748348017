<div class="container-fluid bg-dark">
  <div class="container pt-5 pb-5">
    <div class="row">
      <div class="col-12 col-lg-6 text-gray">
        {{ 'footer.copyright' | translate:dateStringi18n }}
      </div>
      <div class="col-12 col-lg-6 mt-4 mt-lg-0">
        <div class="mb-2">
          <small class="text-light mb-2 d-block"><b>{{ 'footer.legal.title' | translate }}</b></small>
          <div class="link-box">
            <a routerLink="/legal/cookies-policy" class="link-gray link-underline link-underline-opacity-0 d-inline-block">
              <div class="d-flex">
                <span class="icon">
                  <fa-icon [icon]="faCookie" class="me-2"></fa-icon>
                </span>
                {{ 'footer.legal.cookie-policy' | translate }}
              </div>
            </a>
          </div>
          <div class="link-box">
            <a routerLink="/legal/terms-of-service" class="link-gray link-underline link-underline-opacity-0 d-inline-block">
              <div class="d-flex">
                <span class="icon">
                  <fa-icon [icon]="faFileContract" class="me-2"></fa-icon>
                </span>
                {{ 'footer.legal.terms-and-conditions' | translate }}
              </div>
            </a>
          </div>
          <div class="link-box">
            <a routerLink="/legal/privacy-policy" class="link-gray link-underline link-underline-opacity-0 d-inline-block">
              <div class="d-flex">
                <span class="icon">
                  <fa-icon [icon]="faLock" class="me-2"></fa-icon>
                </span>
                {{ 'footer.legal.privacy-policy' | translate }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
