<p-dropdown 
  [options]="servers" 
  [ngModel]="value"
  (ngModelChange)="value = $event; valueChange.emit($event)"
  (onClear)="value = undefined; valueChange.emit(undefined)"
  optionLabel="name" 
  [placeholder]="'common.input.server-dropdown.placeholder' | translate"
  class="w-100 d-inline-block mw-100"
  [styleClass]="'w-100 mw-100' + (disabledDueError ? ' border-danger border rounded-0 bg-danger bg-opacity-50' : '')"
  panelStyleClass="list-removed-padding"
  [virtualScroll]="true"
  [virtualScrollItemSize]="48"
  [filter]="enableFilterDropdown"
  filterBy="name"
  showClear="true"
  [disabled]="disabledDueFetchRequired || disabledDueError"
  [filterPlaceholder]="'common.input.server-dropdown.filter-placeholder' | translate"
  [emptyFilterMessage]="'common.input.server-dropdown.filter-no-result' | translate"
  [emptyMessage]="'common.input.server-dropdown.empty-result' | translate"
  appendTo="body"
>
  <ng-template pTemplate="header" *ngIf="onlyShowGuildsWithZergroshAccess">
    <div class="d-flex align-items-center">
      <a routerLink="/me/servers" class="p-3 d-block w-100 link-light link-underline-opacity-0 hover-bg-shade" style="cursor: pointer;">
        <small><fa-icon [icon]="faPlus" class="me-2"></fa-icon> {{ 'server.sidebar.server-selector.add-server' | translate }}</small>
      </a>
    </div>
    <p-divider type="solid" styleClass="p-0 m-0"></p-divider>
  </ng-template>
  <ng-template pTemplate="selectedItem">
    <div class="d-flex align-items-center" *ngIf="value !== undefined">
      <p-avatar 
        *ngIf="value?.icon === null || value?.icon === undefined"
        label="{{ value.name.substring(0,2).toUpperCase()}} " 
        [ngClass]="['me-2','bg-dark']"
        [style]="{ color: '#ffffff', width: '18px', 'height': '18px', 'font-size': '10px' }"
      ></p-avatar>
      <img 
        *ngIf="value.icon !== null && value.icon !== undefined"
        src="https://cdn.discordapp.com/icons/{{ value.id }}/{{ value.icon }}.png" 
        style="width: 18px" 
        class="me-2"
      />
      <div>{{ value.name }}</div>
    </div>
  </ng-template>
  <ng-template let-server pTemplate="item">
    <div class="d-flex align-items-center">
        <p-avatar 
          *ngIf="server.icon === null || server.icon === undefined"
          label="{{server.name.substring(0,2).toUpperCase()}}" 
          [ngClass]="['me-2','bg-dark']"
          [style]="{ color: '#ffffff', width: '18px', 'height': '18px', 'font-size': '10px' }"
        ></p-avatar>
        <img 
          *ngIf="server.icon !== null && server.icon !== undefined"
          src="https://cdn.discordapp.com/icons/{{ server.id }}/{{ server.icon }}.png" 
          style="width: 18px" 
          class="me-2"
        />
        <div>{{ server.name }}</div>
    </div>
  </ng-template>
</p-dropdown>
<div *ngIf="disabledDueError" class="bg-danger text-white">
  <b><small class="p-2 d-inline-block">An error has occurred while trying to fetch server list :(</small></b>
</div>
